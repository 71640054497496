import { Link } from "gatsby"
import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import CrossChurch from "../images/crosschurch.png"
import LayoutLanding from "../components/layoutLanding"
import SEO from "../components/seo"
import { Collapse } from "@chakra-ui/core"
import Head from "../components/Head"
import EasterBanner from "../components/EasterBanner"

const MainText = styled.h2`
  font-family: Montserrat;
  font-weight: 900;
  color: #312e2e;
  font-size: 1.8rem;
  margin-bottom: 0.25rem;
  @media (max-width: 640px) {
    margin-top: 1rem;
  }
`

const MainLink = styled.a`
  color: #fff;
  background: #f07525;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-family: Montserrat;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  @media (max-width: 640px) {
    margin: 1rem 0 0 0;
    width: 100%;
    justify-content: center;
  }
`

const MainButton = styled(Link)`
  color: #fff;
  background: #f07525;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-family: Montserrat;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  @media (max-width: 640px) {
    margin: 1rem 0 0 0;
    width: 100%;
    justify-content: center;
  }
`

const SecondaryButton = styled.button`
  color: #fff;
  background: #312e2e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-family: Montserrat;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  margin-left: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 640px) {
    margin-top: 1rem;
    margin: 1rem 0 0 0;
    width: 100%;
    justify-content: center;
  }
`

function LandingPage() {
  const [show, setShow] = React.useState(false)
  const [show1, setShow1] = React.useState(false)
  const [show2, setShow2] = React.useState(false)
  const [show3, setShow3] = React.useState(false)
  const [show4, setShow4] = React.useState(false)

  const handleToggle4 = () => setShow4(!show4)
  const handleToggle3 = () => setShow3(!show3)
  const handleToggle2 = () => setShow2(!show2)
  const handleToggle1 = () => setShow1(!show1)
  const handleToggle = () => setShow(!show)

  return (
    <LayoutLanding>
      <Head />
      <SEO title="Making Jesus Known" />
      <div
        css={css`
          background-color: #edf2f7;
          margin-bottom: 4rem;
          display: flex;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
          border-radius: 17px;
          flex-direction: row;
          align-items: center;
          margin-top: 4rem;
          flex-wrap: wrap;
          padding: 3rem 1rem;
          @media (max-width: 640px) {
            flex-direction: column;
            margin: 2rem auto;
            padding: 0 auto;
            align-items: center;
            justify-content: center;
          }
        `}
      >
        <img
          css={css`
            width: 108px;
            margin-left: 4rem;
            @media (max-width: 640px) {
              margin: 0 auto;
            }
          `}
          src={CrossChurch}
          alt=""
        />
        <div
          css={css`
            margin-left: 6rem;
            @media (max-width: 640px) {
              margin: 0 auto;
            }
          `}
        >
          <MainText>Surprise Campus</MainText>
          <div
            css={css`
              display: flex;
              margin-bottom: 0.5rem;
              @media (max-width: 640px) {
                flex-direction: column;
              }
            `}
          >
            <MainButton to="/surprise">
              <svg
                width="22"
                height="22"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                css={css`
                  margin-right: 1rem;
                `}
              >
                <path
                  d="M14 0.4375C21.4922 0.4375 27.5625 6.50781 27.5625 14C27.5625 21.4922 21.4922 27.5625 14 27.5625C6.50781 27.5625 0.4375 21.4922 0.4375 14C0.4375 6.50781 6.50781 0.4375 14 0.4375ZM12.4195 8.29063L16.5484 12.25H6.5625C5.83516 12.25 5.25 12.8352 5.25 13.5625V14.4375C5.25 15.1648 5.83516 15.75 6.5625 15.75H16.5484L12.4195 19.7094C11.8891 20.218 11.8781 21.0656 12.3977 21.5852L12.9992 22.1812C13.5133 22.6953 14.3445 22.6953 14.8531 22.1812L22.1102 14.9297C22.6242 14.4156 22.6242 13.5844 22.1102 13.0758L14.8531 5.81328C14.3391 5.29922 13.5078 5.29922 12.9992 5.81328L12.3977 6.40937C11.8781 6.93437 11.8891 7.78203 12.4195 8.29063Z"
                  fill="white"
                />
              </svg>
              Visit Campus
            </MainButton>
            <SecondaryButton onClick={handleToggle}>
              {show ? "↑" : "↓"}
              &nbsp;&nbsp;Campus Details
            </SecondaryButton>
          </div>
        </div>
        <Collapse mt={4} isOpen={show}>
          <div
            css={css`
              padding: 1rem 1rem;
              margin: 0 auto;
              display: flex;
              width: 80%;
              justify-content: space-evenly;
              align-items: center;
            `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              css={css`
                height: 44px;
                @media (max-width: 640px) {
                  height: 80px;
                }
              `}
            >
              <path d="M0 0l6 4 8-4 6 4v16l-6-4-8 4-6-4V0zm7 6v11l6-3V3L7 6z" />
            </svg>
            <p
              css={css`
                margin-left: 6rem;
                @media (max-width: 640px) {
                  margin-left: 2rem;
                }
              `}
            >
              Cross Church Surprise is located at 17475 W. Bell Road in
              Surprise, AZ. The building sits on the corner of Bell Road and
              175th Avenue just 1 mile west of the 303.
            </p>
          </div>
          <div
            css={css`
              padding: 1rem 1rem;
              margin: 0 auto;
              display: flex;
              width: 80%;
              justify-content: space-evenly;
              align-items: center;
            `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              css={css`
                height: 44px;
                @media (max-width: 640px) {
                  height: 92px;
                }
              `}
            >
              <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-7.59V4h2v5.59l3.95 3.95-1.41 1.41L9 10.41z" />
            </svg>
            <p
              css={css`
                margin-left: 6.5rem;
                @media (max-width: 640px) {
                  margin-left: 2.5rem;
                }
              `}
            >
              This location offers three service times in total. This location
              offers three services on Sunday mornings at 9:00am and 10:30am.
              Our 10:45am worship service is also available live online. The
              worship experience is typically between 60-65 minutes in length.
            </p>
          </div>
          <div
            css={css`
              padding: 1rem 1rem;
              margin: 0 auto;
              display: flex;
              width: 80%;
              justify-content: space-evenly;
              align-items: center;
            `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 44 44"
              css={css`
                height: 44px;
                @media (max-width: 640px) {
                  height: 80px;
                }
              `}
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M44.3807 6.78516L30.6908 0C29.2775 1.95469 26.1486 3.31875 22.4994 3.31875C18.8502 3.31875 15.7213 1.95469 14.308 0L0.618164 6.78516C0.0626947 7.06641 -0.162305 7.74141 0.111913 8.29688L4.13379 16.3477C4.41504 16.9031 5.09004 17.1281 5.64551 16.8539L9.6252 14.9062C10.3705 14.5406 11.2424 15.082 11.2424 15.9187V33.75C11.2424 34.9945 12.2479 36 13.4924 36H31.4924C32.7369 36 33.7424 34.9945 33.7424 33.75V15.9117C33.7424 15.082 34.6143 14.5336 35.3596 14.8992L39.3393 16.8469C39.8947 17.1281 40.5697 16.9031 40.851 16.3406L44.8799 8.29688C45.1611 7.74141 44.9361 7.05937 44.3807 6.78516Z"
                  fill="#312E2E"
                />
              </g>
            </svg>

            <p
              css={css`
                margin-left: 5.5rem;
                @media (max-width: 640px) {
                  margin-left: 2rem;
                }
              `}
            >
              People often wear everything from t-shirts and jeans to collared
              shirts and dresses. Feel free to wear what makes you comfortable!
            </p>
          </div>
        </Collapse>
      </div>
      <div
        css={css`
          background-color: #edf2f7;
          margin-bottom: 4rem;
          display: flex;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
          border-radius: 17px;
          flex-direction: row;
          align-items: center;
          margin-top: 4rem;
          flex-wrap: wrap;
          padding: 3rem 1rem;
          @media (max-width: 640px) {
            flex-direction: column;
            padding: 0 auto;
            align-items: center;
            justify-content: center;
            margin: 2rem auto;
          }
        `}
      >
        <img
          css={css`
            width: 108px;
            margin-left: 4rem;
            @media (max-width: 640px) {
              margin: 0 auto;
            }
          `}
          src={CrossChurch}
          alt=""
        />
        <div
          css={css`
            margin-left: 6rem;
            @media (max-width: 640px) {
              margin: 0 auto;
            }
          `}
        >
          <MainText>Phoenix Campus</MainText>
          <div
            css={css`
              display: flex;
              margin-bottom: 0.5rem;
              @media (max-width: 640px) {
                flex-direction: column;
              }
            `}
          >
            <MainButton to="/phoenix">
              <svg
                width="22"
                height="22"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                css={css`
                  margin-right: 1rem;
                `}
              >
                <path
                  d="M14 0.4375C21.4922 0.4375 27.5625 6.50781 27.5625 14C27.5625 21.4922 21.4922 27.5625 14 27.5625C6.50781 27.5625 0.4375 21.4922 0.4375 14C0.4375 6.50781 6.50781 0.4375 14 0.4375ZM12.4195 8.29063L16.5484 12.25H6.5625C5.83516 12.25 5.25 12.8352 5.25 13.5625V14.4375C5.25 15.1648 5.83516 15.75 6.5625 15.75H16.5484L12.4195 19.7094C11.8891 20.218 11.8781 21.0656 12.3977 21.5852L12.9992 22.1812C13.5133 22.6953 14.3445 22.6953 14.8531 22.1812L22.1102 14.9297C22.6242 14.4156 22.6242 13.5844 22.1102 13.0758L14.8531 5.81328C14.3391 5.29922 13.5078 5.29922 12.9992 5.81328L12.3977 6.40937C11.8781 6.93437 11.8891 7.78203 12.4195 8.29063Z"
                  fill="white"
                />
              </svg>
              Visit Campus
            </MainButton>
            <SecondaryButton onClick={handleToggle1}>
              {show1 ? "↑" : "↓"}
              &nbsp;&nbsp;Campus Details
            </SecondaryButton>
          </div>
        </div>
        <Collapse mt={4} isOpen={show1}>
          <div
            css={css`
              padding: 1rem 1rem;
              margin: 0 auto;
              display: flex;
              width: 80%;
              justify-content: space-evenly;
              align-items: center;
            `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              css={css`
                height: 44px;
                @media (max-width: 640px) {
                  height: 80px;
                }
              `}
            >
              <path d="M0 0l6 4 8-4 6 4v16l-6-4-8 4-6-4V0zm7 6v11l6-3V3L7 6z" />
            </svg>
            <p
              css={css`
                margin-left: 6rem;
                @media (max-width: 640px) {
                  margin-left: 2rem;
                }
              `}
            >
              Cross Church Phoenix is located at 1827 W. Grovers Ave. in
              Phoenix, AZ 85023. The building sits on the corner of 19th Avenue
              and Grovers just south of Union Hills.
            </p>
          </div>
          <div
            css={css`
              padding: 1rem 1rem;
              margin: 0 auto;
              display: flex;
              width: 80%;
              justify-content: space-evenly;
              align-items: center;
            `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              css={css`
                height: 44px;
                @media (max-width: 640px) {
                  height: 92px;
                }
              `}
            >
              <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-7.59V4h2v5.59l3.95 3.95-1.41 1.41L9 10.41z" />
            </svg>
            <p
              css={css`
                margin-left: 6.5rem;
                @media (max-width: 640px) {
                  margin-left: 2.5rem;
                }
              `}
            >
              This location offers two service times at 9:00am and 10:30am on
              Sunday mornings. Our 10:30am worship service is also available
              live online. The worship experience is typically between 60-65
              minutes in length.
            </p>
          </div>
          <div
            css={css`
              padding: 1rem 1rem;
              margin: 0 auto;
              display: flex;
              width: 80%;
              justify-content: space-evenly;
              align-items: center;
            `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 44 44"
              css={css`
                height: 44px;
                @media (max-width: 640px) {
                  height: 80px;
                }
              `}
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M44.3807 6.78516L30.6908 0C29.2775 1.95469 26.1486 3.31875 22.4994 3.31875C18.8502 3.31875 15.7213 1.95469 14.308 0L0.618164 6.78516C0.0626947 7.06641 -0.162305 7.74141 0.111913 8.29688L4.13379 16.3477C4.41504 16.9031 5.09004 17.1281 5.64551 16.8539L9.6252 14.9062C10.3705 14.5406 11.2424 15.082 11.2424 15.9187V33.75C11.2424 34.9945 12.2479 36 13.4924 36H31.4924C32.7369 36 33.7424 34.9945 33.7424 33.75V15.9117C33.7424 15.082 34.6143 14.5336 35.3596 14.8992L39.3393 16.8469C39.8947 17.1281 40.5697 16.9031 40.851 16.3406L44.8799 8.29688C45.1611 7.74141 44.9361 7.05937 44.3807 6.78516Z"
                  fill="#312E2E"
                />
              </g>
            </svg>

            <p
              css={css`
                margin-left: 5.5rem;
                @media (max-width: 640px) {
                  margin-left: 2rem;
                }
              `}
            >
              People often wear everything from t-shirts and jeans to collared
              shirts and dresses. Feel free to wear what makes you comfortable!
            </p>
          </div>
        </Collapse>
      </div>
      <div
        css={css`
          background-color: #edf2f7;
          margin-bottom: 4rem;
          display: flex;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
          border-radius: 17px;
          flex-direction: row;
          align-items: center;
          margin-top: 4rem;
          flex-wrap: wrap;
          padding: 3rem 1rem;
          @media (max-width: 640px) {
            flex-direction: column;
            padding: 0 auto;
            align-items: center;
            justify-content: center;
            margin: 2rem auto;
          }
        `}
      >
        <img
          css={css`
            width: 108px;
            margin-left: 4rem;
            @media (max-width: 640px) {
              margin: 0 auto;
            }
          `}
          src={CrossChurch}
          alt=""
        />
        <div
          css={css`
            margin-left: 6rem;
            @media (max-width: 640px) {
              margin: 0 auto;
              text-align: center;
            }
          `}
        >
          <MainText
            css={css`
              display: flex;
              flex-direction: column;
              padding-bottom: 0.5rem;
            `}
          >
            Cornville Campus
          </MainText>
          <div
            css={css`
              display: flex;
              margin-bottom: 0.5rem;
              @media (max-width: 640px) {
                flex-direction: column;
              }
            `}
          >
            <MainButton to="/cornville">
              <svg
                width="22"
                height="22"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                css={css`
                  margin-right: 1rem;
                `}
              >
                <path
                  d="M14 0.4375C21.4922 0.4375 27.5625 6.50781 27.5625 14C27.5625 21.4922 21.4922 27.5625 14 27.5625C6.50781 27.5625 0.4375 21.4922 0.4375 14C0.4375 6.50781 6.50781 0.4375 14 0.4375ZM12.4195 8.29063L16.5484 12.25H6.5625C5.83516 12.25 5.25 12.8352 5.25 13.5625V14.4375C5.25 15.1648 5.83516 15.75 6.5625 15.75H16.5484L12.4195 19.7094C11.8891 20.218 11.8781 21.0656 12.3977 21.5852L12.9992 22.1812C13.5133 22.6953 14.3445 22.6953 14.8531 22.1812L22.1102 14.9297C22.6242 14.4156 22.6242 13.5844 22.1102 13.0758L14.8531 5.81328C14.3391 5.29922 13.5078 5.29922 12.9992 5.81328L12.3977 6.40937C11.8781 6.93437 11.8891 7.78203 12.4195 8.29063Z"
                  fill="white"
                />
              </svg>
              Visit Campus
            </MainButton>
            <SecondaryButton onClick={handleToggle3}>
              {show3 ? "↑" : "↓"}
              &nbsp;&nbsp;Campus Details
            </SecondaryButton>
          </div>
        </div>
        <Collapse mt={4} isOpen={show3}>
          <div
            css={css`
              padding: 1rem 1rem;
              margin: 0 auto;
              display: flex;
              width: 80%;
              justify-content: space-evenly;
              align-items: center;
            `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              css={css`
                height: 44px;
                @media (max-width: 640px) {
                  height: 80px;
                }
              `}
            >
              <path d="M0 0l6 4 8-4 6 4v16l-6-4-8 4-6-4V0zm7 6v11l6-3V3L7 6z" />
            </svg>
            <p
              css={css`
                margin-left: 6rem;
                @media (max-width: 640px) {
                  margin-left: 2rem;
                }
              `}
            >
              Cross Church Cornville is located at 11340 E Circle Drive in
              Cornville, AZ 86325. The building sits just north of Cornville
              Road and east of S. Page Springs Road.
            </p>
          </div>
          <div
            css={css`
              padding: 1rem 1rem;
              margin: 0 auto;
              display: flex;
              width: 80%;
              justify-content: space-evenly;
              align-items: center;
            `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              css={css`
                height: 44px;
                @media (max-width: 640px) {
                  height: 92px;
                }
              `}
            >
              <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-7.59V4h2v5.59l3.95 3.95-1.41 1.41L9 10.41z" />
            </svg>
            <p
              css={css`
                margin-left: 6.5rem;
                @media (max-width: 640px) {
                  margin-left: 2.5rem;
                }
              `}
            >
              This location offers two service times at 9:00am and 10:30am on
              Sunday mornings. Our 10:30am worship service is also available
              live online. The worship experience is typically between 60-65
              minutes in length.
            </p>
          </div>
          <div
            css={css`
              padding: 1rem 1rem;
              margin: 0 auto;
              display: flex;
              width: 80%;
              justify-content: space-evenly;
              align-items: center;
            `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 44 44"
              css={css`
                height: 44px;
                @media (max-width: 640px) {
                  height: 80px;
                }
              `}
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M44.3807 6.78516L30.6908 0C29.2775 1.95469 26.1486 3.31875 22.4994 3.31875C18.8502 3.31875 15.7213 1.95469 14.308 0L0.618164 6.78516C0.0626947 7.06641 -0.162305 7.74141 0.111913 8.29688L4.13379 16.3477C4.41504 16.9031 5.09004 17.1281 5.64551 16.8539L9.6252 14.9062C10.3705 14.5406 11.2424 15.082 11.2424 15.9187V33.75C11.2424 34.9945 12.2479 36 13.4924 36H31.4924C32.7369 36 33.7424 34.9945 33.7424 33.75V15.9117C33.7424 15.082 34.6143 14.5336 35.3596 14.8992L39.3393 16.8469C39.8947 17.1281 40.5697 16.9031 40.851 16.3406L44.8799 8.29688C45.1611 7.74141 44.9361 7.05937 44.3807 6.78516Z"
                  fill="#312E2E"
                />
              </g>
            </svg>

            <p
              css={css`
                margin-left: 5.5rem;
                @media (max-width: 640px) {
                  margin-left: 2rem;
                }
              `}
            >
              People often wear everything from t-shirts and jeans to collared
              shirts and dresses. Feel free to wear what makes you comfortable!
            </p>
          </div>
        </Collapse>
      </div>
      <div
        css={css`
          background-color: #edf2f7;
          margin-bottom: 4rem;
          display: flex;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
          border-radius: 17px;
          flex-direction: row;
          align-items: center;
          margin-top: 4rem;
          flex-wrap: wrap;
          padding: 3rem 1rem;
          @media (max-width: 640px) {
            flex-direction: column;
            padding: 0 auto;
            align-items: center;
            justify-content: center;
            margin: 2rem auto;
          }
        `}
      >
        <img
          css={css`
            width: 108px;
            margin-left: 4rem;
            @media (max-width: 640px) {
              margin: 0 auto;
            }
          `}
          src={CrossChurch}
          alt=""
        />
        <div
          css={css`
            margin-left: 6rem;
            @media (max-width: 640px) {
              margin: 0 auto;
              text-align: center;
            }
          `}
        >
          <MainText
            css={css`
              display: flex;
              flex-direction: column;
              padding-bottom: 0.5rem;
            `}
          >
            El Mirage Campus
          </MainText>
          <div
            css={css`
              display: flex;
              margin-bottom: 0.5rem;
              @media (max-width: 640px) {
                flex-direction: column;
              }
            `}
          >
            <MainButton to="/elmirage">
              <svg
                width="22"
                height="22"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                css={css`
                  margin-right: 1rem;
                `}
              >
                <path
                  d="M14 0.4375C21.4922 0.4375 27.5625 6.50781 27.5625 14C27.5625 21.4922 21.4922 27.5625 14 27.5625C6.50781 27.5625 0.4375 21.4922 0.4375 14C0.4375 6.50781 6.50781 0.4375 14 0.4375ZM12.4195 8.29063L16.5484 12.25H6.5625C5.83516 12.25 5.25 12.8352 5.25 13.5625V14.4375C5.25 15.1648 5.83516 15.75 6.5625 15.75H16.5484L12.4195 19.7094C11.8891 20.218 11.8781 21.0656 12.3977 21.5852L12.9992 22.1812C13.5133 22.6953 14.3445 22.6953 14.8531 22.1812L22.1102 14.9297C22.6242 14.4156 22.6242 13.5844 22.1102 13.0758L14.8531 5.81328C14.3391 5.29922 13.5078 5.29922 12.9992 5.81328L12.3977 6.40937C11.8781 6.93437 11.8891 7.78203 12.4195 8.29063Z"
                  fill="white"
                />
              </svg>
              Visit Campus
            </MainButton>
            <SecondaryButton onClick={handleToggle4}>
              {show3 ? "↑" : "↓"}
              &nbsp;&nbsp;Campus Details
            </SecondaryButton>
          </div>
        </div>
        <Collapse mt={4} isOpen={show4}>
          <div
            css={css`
              padding: 1rem 1rem;
              margin: 0 auto;
              display: flex;
              width: 80%;
              justify-content: space-evenly;
              align-items: center;
            `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              css={css`
                height: 44px;
                @media (max-width: 640px) {
                  height: 80px;
                }
              `}
            >
              <path d="M0 0l6 4 8-4 6 4v16l-6-4-8 4-6-4V0zm7 6v11l6-3V3L7 6z" />
            </svg>
            <p
              css={css`
                margin-left: 6rem;
                @media (max-width: 640px) {
                  margin-left: 2rem;
                }
              `}
            >
              Cross Church El Mirage is located at 11006 N. El Mirage Road in El
              Mirage, AZ 85335. The building sits just north of Peoria Ave.
            </p>
          </div>
          <div
            css={css`
              padding: 1rem 1rem;
              margin: 0 auto;
              display: flex;
              width: 80%;
              justify-content: space-evenly;
              align-items: center;
            `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              css={css`
                height: 44px;
                @media (max-width: 640px) {
                  height: 92px;
                }
              `}
            >
              <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-7.59V4h2v5.59l3.95 3.95-1.41 1.41L9 10.41z" />
            </svg>
            <p
              css={css`
                margin-left: 6.5rem;
                @media (max-width: 640px) {
                  margin-left: 2.5rem;
                }
              `}
            >
              This location offers two service times at 9:00am and 10:30am on
              Sunday mornings. Our 10:30am worship service is also available
              live online. The worship experience is typically between 60-65
              minutes in length.
            </p>
          </div>
          <div
            css={css`
              padding: 1rem 1rem;
              margin: 0 auto;
              display: flex;
              width: 80%;
              justify-content: space-evenly;
              align-items: center;
            `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 44 44"
              css={css`
                height: 44px;
                @media (max-width: 640px) {
                  height: 80px;
                }
              `}
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M44.3807 6.78516L30.6908 0C29.2775 1.95469 26.1486 3.31875 22.4994 3.31875C18.8502 3.31875 15.7213 1.95469 14.308 0L0.618164 6.78516C0.0626947 7.06641 -0.162305 7.74141 0.111913 8.29688L4.13379 16.3477C4.41504 16.9031 5.09004 17.1281 5.64551 16.8539L9.6252 14.9062C10.3705 14.5406 11.2424 15.082 11.2424 15.9187V33.75C11.2424 34.9945 12.2479 36 13.4924 36H31.4924C32.7369 36 33.7424 34.9945 33.7424 33.75V15.9117C33.7424 15.082 34.6143 14.5336 35.3596 14.8992L39.3393 16.8469C39.8947 17.1281 40.5697 16.9031 40.851 16.3406L44.8799 8.29688C45.1611 7.74141 44.9361 7.05937 44.3807 6.78516Z"
                  fill="#312E2E"
                />
              </g>
            </svg>

            <p
              css={css`
                margin-left: 5.5rem;
                @media (max-width: 640px) {
                  margin-left: 2rem;
                }
              `}
            >
              People often wear everything from t-shirts and jeans to collared
              shirts and dresses. Feel free to wear what makes you comfortable!
            </p>
          </div>
        </Collapse>
      </div>
      <div
        css={css`
          background-color: #edf2f7;
          margin-bottom: 4rem;
          display: flex;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
          border-radius: 17px;
          flex-direction: row;
          align-items: center;
          margin-top: 4rem;
          flex-wrap: wrap;
          padding: 3rem 1rem;
          @media (max-width: 640px) {
            flex-direction: column;
            padding: 0 auto;
            align-items: center;
            justify-content: center;
            margin: 2rem auto;
          }
        `}
      >
        <img
          css={css`
            width: 108px;
            margin-left: 4rem;
            @media (max-width: 640px) {
              margin: 0 auto;
            }
          `}
          src={CrossChurch}
          alt=""
        />
        <div
          css={css`
            margin-left: 6rem;
            @media (max-width: 640px) {
              margin: 0 auto;
              text-align: center;
            }
          `}
        >
          <MainText css={css``}>Myanmar Campus</MainText>
          <div
            css={css`
              display: flex;
              margin-bottom: 0.5rem;
              @media (max-width: 640px) {
                flex-direction: column;
              }
            `}
          >
            <MainButton to="/myanmar">
              <svg
                width="22"
                height="22"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                css={css`
                  margin-right: 1rem;
                `}
              >
                <path
                  d="M14 0.4375C21.4922 0.4375 27.5625 6.50781 27.5625 14C27.5625 21.4922 21.4922 27.5625 14 27.5625C6.50781 27.5625 0.4375 21.4922 0.4375 14C0.4375 6.50781 6.50781 0.4375 14 0.4375ZM12.4195 8.29063L16.5484 12.25H6.5625C5.83516 12.25 5.25 12.8352 5.25 13.5625V14.4375C5.25 15.1648 5.83516 15.75 6.5625 15.75H16.5484L12.4195 19.7094C11.8891 20.218 11.8781 21.0656 12.3977 21.5852L12.9992 22.1812C13.5133 22.6953 14.3445 22.6953 14.8531 22.1812L22.1102 14.9297C22.6242 14.4156 22.6242 13.5844 22.1102 13.0758L14.8531 5.81328C14.3391 5.29922 13.5078 5.29922 12.9992 5.81328L12.3977 6.40937C11.8781 6.93437 11.8891 7.78203 12.4195 8.29063Z"
                  fill="white"
                />
              </svg>
              Visit Campus
            </MainButton>
          </div>
        </div>
      </div>
      <div
        css={css`
          background-color: #edf2f7;
          margin-bottom: 4rem;
          display: flex;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
          border-radius: 17px;
          flex-direction: row;
          align-items: center;
          margin-top: 4rem;
          flex-wrap: wrap;
          padding: 3rem 1rem;
          @media (max-width: 640px) {
            flex-direction: column;
            padding: 0 auto;
            align-items: center;
            justify-content: center;
            margin: 2rem auto;
          }
        `}
      >
        <img
          css={css`
            width: 108px;
            margin-left: 4rem;
            @media (max-width: 640px) {
              margin: 0 auto;
            }
          `}
          src={CrossChurch}
          alt=""
        />
        <div
          css={css`
            margin-left: 6rem;
            @media (max-width: 640px) {
              margin: 0 auto;
              text-align: center;
            }
          `}
        >
          <MainText css={css``}>Philippines Campus</MainText>
          <div
            css={css`
              display: flex;
              margin-bottom: 0.5rem;
              @media (max-width: 640px) {
                flex-direction: column;
              }
            `}
          >
            <MainButton to="/philippines">
              <svg
                width="22"
                height="22"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                css={css`
                  margin-right: 1rem;
                `}
              >
                <path
                  d="M14 0.4375C21.4922 0.4375 27.5625 6.50781 27.5625 14C27.5625 21.4922 21.4922 27.5625 14 27.5625C6.50781 27.5625 0.4375 21.4922 0.4375 14C0.4375 6.50781 6.50781 0.4375 14 0.4375ZM12.4195 8.29063L16.5484 12.25H6.5625C5.83516 12.25 5.25 12.8352 5.25 13.5625V14.4375C5.25 15.1648 5.83516 15.75 6.5625 15.75H16.5484L12.4195 19.7094C11.8891 20.218 11.8781 21.0656 12.3977 21.5852L12.9992 22.1812C13.5133 22.6953 14.3445 22.6953 14.8531 22.1812L22.1102 14.9297C22.6242 14.4156 22.6242 13.5844 22.1102 13.0758L14.8531 5.81328C14.3391 5.29922 13.5078 5.29922 12.9992 5.81328L12.3977 6.40937C11.8781 6.93437 11.8891 7.78203 12.4195 8.29063Z"
                  fill="white"
                />
              </svg>
              Visit Campus
            </MainButton>
          </div>
        </div>
      </div>
    </LayoutLanding>
  )
}

export default LandingPage
