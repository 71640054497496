import React from "react"
import VidLanding from "../images/herovid_landing.mp4"
import styled from "@emotion/styled"
import PosterImg from "../images/sermons.jpg"
import { css } from "@emotion/core"
import { Link } from "gatsby"

const HeroText = styled.h1`
  font-family: "Montserrat";
  font-size: 5rem;
  color: #fff;
  font-weight: 900;
  text-transform: uppercase;
  text-shadow: 0.5px 0.5px 0.5px #333;
  line-height: 0.8;
  margin: 4rem auto 1rem;
  text-align: center;
  @media (max-width: 640px) {
    font-size: 3.5rem;
    margin: 0;
  }
`

const SecondaryText = styled.h2`
  font-family: "Montserrat";
  font-size: 2rem;
  color: #fff;
  font-weight: 900;
  text-transform: uppercase;
  padding-top: 0;
  text-shadow: 0.5px 0.5px 0.5px #333;
  line-height: 0.8;
  text-align: center;
  @media (max-width: 640px) {
    font-size: 1.25rem;
    margin-top: 0.25rem;
  }
`

const HeroLanding = () => (
  <div
    css={css`
      position: relative;
      @media (max-width: 640px) {
        height: 400px;
      }
    `}
  >
    <div
      css={css`
        position: absolute;
        z-index: 1;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        overflow: hidden;
        background-size: cover;
        background-color: #312e2e;
        background-repeat: no-repeat;
        background-position: 0% 50%;
        @media (max-width: 640px) {
          height: 100%;
          margin-bottom: 0;
        }
      `}
    >
      <video
        loop
        muted
        autoPlay
        playsInline
        poster={PosterImg}
        onPlaying="this.controls=false"
        css={css`
          margin: auto;
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 0%;
          transform: translate(0%, -50%);
          visibility: visible;
          opacity: 1;
          width: 100%;
          height: 100%;
          object-fit: cover;
          @media (max-width: 640px) {
            height: 100%;
            top: 50%;
            margin-bottom: 0;
          }
        `}
        src={VidLanding}
        type="video/mp4"
      />
    </div>
    <div
      css={css`
        position: relative;
        z-index: 3;
        height: 600px;
        margin: 2rem auto 4rem;
        padding-top: 200px;
        max-width: 90vw;
        width: 1050px;
        @media (max-width: 640px) {
          padding-top: 80px;
        }
      `}
    >
      <HeroText>Making Jesus Known</HeroText>
      <SecondaryText>One Church. Multiple Campuses.</SecondaryText>
      <div
        css={css`
        display: flex;
        justify-content: center;
        align-items; center;
        @media (max-width: 640px) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      `}
      >
        <Link
          css={css`
            text-decoration: none;
            color: #312e2e;
            background: #fff;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
            font-family: Montserrat;
            font-weight: 600;
            border-radius: 8px;
            text-transform: uppercase;
            margin: 3rem 1rem;
            padding: 0.8rem 3rem;
            font-size: 1.2rem;
            border: 0;
            cursor: pointer;
            &:hover {
              background: #f07525;
              color: #fff;
            }
            @media (max-width: 640px) {
              margin-top: 3rem;
              margin-bottom: 0;
              width: 100%;
              text-align: center;
            }
          `}
          to="/churchnearme"
        >
          Find a Church Near You
        </Link>
        <Link
          css={css`
            text-decoration: none;
            color: #fff;
            background: #f07525;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
            font-family: Montserrat;
            font-weight: 600;
            border-radius: 8px;
            text-transform: uppercase;
            margin: 3rem 1rem;
            padding: 0.8rem 3rem;
            font-size: 1.2rem;
            border: 0;
            cursor: pointer;
            &:hover {
              background: #fff;
              color: #312e2e;
            }
            @media (max-width: 640px) {
              margin: 0;
              width: 100%;
              text-align: center;
              margin-top: 1rem;
            }
          `}
          to="/visit"
        >
          Plan a Visit
        </Link>
      </div>
    </div>
  </div>
)

export default HeroLanding
