import React from "react"
import Helmet from "react-helmet"
import HeaderImage from "../images/makingJesusKnown.jpg"

export default () => (
  <Helmet>
    {/* <!-- Primary Meta Tags --> */}
    <title>Cross Church Cares</title>
    <meta name="title" content="Cross Church Cares" />
    <meta
      name="description"
      content="Making Jesus Known. One Church. Multiple Campuses."
    />
    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.crosschurchcares.com/" />
    <meta property="og:title" content="Cross Church Cares" />
    <meta
      property="og:description"
      content="Making Jesus Known. One Church. Multiple Campuses."
    />
    <meta property="og:image" content={HeaderImage} />
    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content="https://www.crosschurchcares.com/" />
    <meta property="twitter:title" content="Cross Church Cares" />
    <meta
      property="twitter:description"
      content="Making Jesus Known. One Church. Multiple Campuses."
    />
    <meta property="twitter:image" content={HeaderImage} />
  </Helmet>
)
